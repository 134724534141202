import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SubBanner from '../components/SubBanner';
import ModularBlocks from '../components/ModularBlocks';

const DefaultPageTemplate = ({
  data: {
    datoCmsPage: { seoMetaTags, noIndex, title, bannerImage, modularBlocks },
    allShopifyProduct: { shopifyProducts },
  },
}) => {
  const modifyModularBlocks = items => {
    return items.map(modularBlock => {
      if (modularBlock.model.apiKey === 'featured_products_modular_block') {
        const productHandles = modularBlock.products.map(
          ({ shopifyProduct }) => shopifyProduct
        );

        const products = shopifyProducts.filter(shopifyProduct =>
          productHandles.includes(shopifyProduct.handle)
        );

        return { ...modularBlock, items: products };
      } else {
        return modularBlock;
      }
    });
  };

  return (
    <Layout seo={seoMetaTags} noIndex={noIndex}>
      <main>
        <SubBanner heading={title} image={bannerImage} />
        <ModularBlocks items={modifyModularBlocks(modularBlocks)} />
      </main>
    </Layout>
  );
};

export const DefaultPageTemplateQuery = graphql`
  query DefaultPageTemplateQuery($id: String!) {
    datoCmsPage(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      noIndex
      title
      bannerImage {
        ...SubBannerImageFragment
      }
      modularBlocks {
        ...AccordionModularBlockFragment
        ...ContainedImageModularBlockFragment
        ...ContentModularBlockFragment
        ...ExternalVideoModularBlockFragment
        ...FeaturedProductsModularBlockFragment
        ...FullWidthImageModularBlockFragment
        ...IconContentBlocksModularBlockFragment
        ...ImageContentModularBlockV1Fragment
        ...ImageContentModularBlockV2Fragment
        ...ImagesGridModularBlockFragment
        ...InternalVideoModularBlockFragment
        ...LinkCardsModularBlockFragment
        ...TableModularBlockFragment
        ...TabsModularBlockFragment
        ...TestimonialsModularBlockFragment
        ...TimelineModularBlockFragment
      }
    }
    allShopifyProduct {
      shopifyProducts: nodes {
        ...ProductCardFragment
      }
    }
  }
`;

export default DefaultPageTemplate;
